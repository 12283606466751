import { ClubTheme } from '../classes';

const base = process.env.NODE_ENV === 'cordova' ? process.env.VUE_APP_BASE_URL : '/';

// hacky and maybe not needed
const defaultClub = new ClubTheme({
  clubId: 0,
  clubName: 'Golf Club Name',
});

const stokePark = new ClubTheme({
  clubId: 563,
  clubName: 'Stoke Park',
  loginLogo: 'loginLogo.png',
  loginLogoAlternative: 'logo.png',
  footerBackground: 'footer-bg.jpg',
  quotesTemplate: 0,
  fonts: [
    {
      family: 'Perpetua',
      src: `${base}fonts/Perpetua.ttf`,
      format: 'woff2',
    },
    {
      family: 'PerpetuaBold',
      src: `${base}fonts/Perpetua-Bold.woff2`,
      format: 'woff2',
    },
  ],
  golfBookingImage: 'golfBooking.jpg',
  buddiesText: 'friend',
  buddiesTextPlural: 'friends',
});

const theMere = new ClubTheme({
  clubId: 541,
  clubName: 'The Mere',
  loginLogo: 'logo.png',
  loginLogoAlternative: 'logo.png',
  footerBackground: 'footer-bg.jpg',
  quotesTemplate: 0,
  fonts: [
    {
      family: 'Lafabrique',
      src: `${base}fonts/lafabrique-regular.woff`,
      format: 'woff',
    },
    {
      family: 'ModernMTPro',
      src: `${base}fonts/ModernMTPro-Display.otf`,
      format: 'opentype',
    },
  ],
  golfBookingImage: 'golfBooking.jpg',
});


const theGroveSpa = new ClubTheme({
  clubId: 523,
  clubName: 'Sequoia Spa at The Grove',
  loginLogo: 'theGroveSvg.svg',
  loginLogoAlternative: 'theGroveSvgBlue.svg',
  footerBackground: 'footer-bg.jpg',
  quotesTemplate: 0,
  fonts: [
    {
      family: 'Lafabrique',
      src: `${base}fonts/lafabrique-regular.woff`,
      format: 'woff',
    },
    {
      family: 'ModernMTPro',
      src: `${base}fonts/ModernMTPro-Display.otf`,
      format: 'opentype',
    },
  ],
});

const theGrove = new ClubTheme({
  clubId: 449,
  clubName: 'The Grove',
  loginLogo: 'theGroveSvg.svg',
  loginLogoAlternative: 'theGroveSvgBlue.svg',
  footerBackground: '',
  quotesTemplate: 0,
  fonts: [
    {
      family: 'Lafabrique',
      src: `${base}fonts/lafabrique-regular.woff`,
      format: 'woff',
    },
    {
      family: 'ModernMTPro',
      src: `${base}fonts/ModernMTPro-Display.otf`,
      format: 'opentype',
    },
  ],
});

const jcb = new ClubTheme({
  clubId: 537,
  clubName: 'JCB Golf & Country Club',
  loginLogo: 'JCB.svg',
  loginLogoAlternative: 'JCBBlue.svg',
  footerBackground: 'footer-bg.jpg',
  quotesTemplate: 0,
  fonts: [
    {
      family: 'TrajanPro',
      src: `${base}fonts/trajanpro3-regular.woff`,
      format: 'woff',
    },
    {
      family: 'JCBEuro',
      src: `${base}fonts/jcber-webfont.woff`,
      format: 'woff',
    },
  ],
  golfBookingImage: 'golfBooking.jpg',
  buddiesText: 'colleague',
  buddiesTextPlural: 'colleagues',
});

const playMoreGolfResort = new ClubTheme({
  clubId: 151,
  clubName: 'The Grove',
  loginLogo: 'theGroveSvg.svg',
  loginLogoAlternative: 'theGroveSvgBlue.svg',
  footerBackground: 'footer-bg.jpg',
  quotesTemplate: 0,
  fonts: [
    {
      family: 'Lafabrique',
      src: 'fonts/lafabrique-regular.woff',
      format: 'woff',
    },
    {
      family: 'ModernMTPro',
      src: 'fonts/ModernMTPro-Display.otf',
      format: 'opentype',
    },
  ],
});

const theGroveTest = new ClubTheme({
  clubId: 238,
  clubName: 'The Grove',
  loginLogo: 'theGroveSvg.svg',
  loginLogoAlternative: 'theGroveSvgBlue.svg',
  footerBackground: 'footer-bg.jpg',
  quotesTemplate: 0,
  fonts: [
    {
      family: 'Lafabrique',
      src: '/fonts/lafabrique-regular.woff',
      format: 'woff',
    },
    {
      family: 'ModernMTPro',
      src: '/fonts/ModernMTPro-Display.otf',
      format: 'opentype',
    },
  ],
});

const foxHills = new ClubTheme({
  clubId: 234,
  clubName: 'Foxhills Club & Resort',
  loginLogo: 'logo.png',
  loginLogoAlternative: 'logoAlternative.png',
  quotesTemplate: 1,
  fonts: [
    {
      family: '"ITC New Baskerville W01",Georgia,"Times New Roman",Times,serif',
      src: '/fonts/foxhills2.woff2',
      format: 'woff',
    },
  ],
});

const eighteenClubDemo = new ClubTheme({
  clubId: 259,
  clubName: 'EighteenClub',
  loginLogo: 'xviii.jpg',
  loginLogoAlternative: 'xviii.jpg',
  footerBackground: 'footer-bg.jpg',
  quotesTemplate: 1,
  fonts: [
    {
      family: 'Lafabrique',
      src: '/fonts/lafabrique-regular.woff',
      format: 'woff',
    },
    {
      family: 'ModernMTPro',
      src: '/fonts/ModernMTPro-Display.otf',
      format: 'opentype',
    },
  ],
});

const eighteenClubDemo2 = new ClubTheme({
  clubId: 260,
  clubName: 'EighteenClub',
  loginLogo: 'xviii.jpg',
  loginLogoAlternative: 'xviii.jpg',
  footerBackground: 'footer-bg.jpg',
  quotesTemplate: 1,
  fonts: [
    {
      family: 'Lafabrique',
      src: '/fonts/lafabrique-regular.woff',
      format: 'woff',
    },
    {
      family: 'ModernMTPro',
      src: '/fonts/ModernMTPro-Display.otf',
      format: 'opentype',
    },
  ],
});

const themeList = [
  defaultClub,
  theGrove,
  theGroveTest,
  foxHills,
  playMoreGolfResort,
  eighteenClubDemo,
  theGroveSpa,
  jcb,
  theMere,
  eighteenClubDemo2,
  stokePark,
];

export { themeList };
